<div class="moj-multi-file-upload moj-multi-file-upload--enhanced">
  <div class="moj-multi-file-upload__upload">
    <div class="govuk-form-group">
      <label [for]="id" [class]="'govuk-label govuk-label--' + headerSize()" id="{{ 'l.' + id }}">{{ label() }}</label>

      @if (!hint()) {
        <span [id]="id + '-hint-file-size'" class="govuk-hint">
          Your document must be a DOC, DOCX, XLS, XLSX, PPT, PPTX, VSD, VSDX, JPG, JPEG, PDF, PNG, TIF, TXT, DIB, BMP or
          CSV file, no more than 20MB.
        </span>
      } @else {
        <span [id]="id + '-hint'" class="govuk-hint">{{ hint() }}</span>
      }
      @if (shouldDisplayErrors) {
        <govuk-error-message [errors]="control.errors" [identifier]="id"> </govuk-error-message>
      }

      <div
        (dragleave)="onDragLeave()"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)"
        [class.moj-multi-file-upload--dragover]="isDraggedOver"
        class="moj-multi-file-upload__dropzone govuk-!-margin-top-4"
      >
        <input
          #input
          (blur)="onFileBlur()"
          (change)="onFileChange($event)"
          (focus)="onFileFocus()"
          [accept]="accepted()"
          [class.moj-multi-file-upload--focused]="isFocused"
          [disabled]="isDisabled"
          [id]="id"
          [name]="id"
          [attr.aria-labelledby]="'l.' + id + ' ld.' + id"
          class="govuk-file-upload moj-multi-file-upload__input"
          multiple
          type="file"
        />

        <p class="govuk-body">{{ dropzoneHintText() }}</p>

        <label
          [class.govuk-button--disabled]="isDisabled"
          [for]="id"
          id="{{ 'ld.' + id }}"
          class="govuk-button govuk-button--secondary"
        >
          {{ dropzoneButtonText() }}
        </label>

        <div aria-live="polite" class="govuk-visually-hidden" role="status">{{ uploadStatusText$ | async }}</div>
      </div>
    </div>
  </div>

  <cca-file-upload-list
    [listTitle]="listTitle()"
    [headerSize]="headerSize()"
    (fileDelete)="onFileDeleteClick($event)"
    [files]="uploadedFiles$ | async"
    [isDisabled]="isDisabled"
  ></cca-file-upload-list>
</div>
