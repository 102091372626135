<section data-testid="password-form">
  <div
    autoComplete="username email"
    class="govuk-!-display-none"
    formControlName="email"
    govuk-text-input
    inputType="email"
    label="Email"
  ></div>

  <div class="row-flex align-items-end">
    <div class="col-flex-three-quarters">
      <div
        autoComplete="new-password"
        formControlName="password"
        govuk-text-input
        [inputType]="passwordInputType"
        [label]="passwordLabel"
        hint="Your password must be 12 characters or longer and can include letters, numbers and symbols or a combination of three random words."
      ></div>
    </div>
    <div class="col-flex-one-quarter">
      <button govukSecondaryButton type="button" (click)="togglePassword()">{{ showLabel }}</button>
    </div>
  </div>

  <div class="govuk-grid-row">
    <div class="govuk-grid-column-three-quarters">
      <password-strength-meter
        (strengthChange)="passwordStrength = $event"
        [colors]="['#d4351c', '#d4351c', '#f47738', '#00703c', '#00703c']"
        [password]="formGroupDirective.form.get('password').value"
        id="strengthMeter"
      ></password-strength-meter>
      <div class="govuk-hint" id="strength-hint">
        Password strength
        @if (passwordStrength > 0) {
          @if (passwordStrength >= 3) {
            <govuk-tag color="green" role="alert">strong</govuk-tag>
          }
          @if (passwordStrength === 2) {
            <govuk-tag color="orange" role="alert">medium</govuk-tag>
          }
          @if (passwordStrength < 2) {
            <govuk-tag color="red" role="alert">weak</govuk-tag>
          }
        }
      </div>
    </div>
  </div>

  <div class="row-flex">
    <div class="col-flex-three-quarters">
      <div
        autoComplete="new-password"
        formControlName="validatePassword"
        govuk-text-input
        [inputType]="passwordInputType"
        [label]="confirmPasswordLabel"
      ></div>
    </div>
  </div>
</section>
