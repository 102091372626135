<div #current class="govuk-!-display-none">
  <ng-content select="[slot=current]"></ng-content>
</div>

<div #previous class="govuk-!-display-none">
  <ng-content select="[slot=previous]"></ng-content>
</div>

<!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus -->
<div class="highlight" (click)="onClickDiff($event)" (keydown)="onClickDiff($event)" [innerHtml]="htmlDiff"></div>
