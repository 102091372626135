<div
  govuk-text-input
  autoComplete="address-line1"
  formControlName="line1"
  label="Address line 1"
  widthClass="govuk-!-width-two-thirds"
></div>

<div
  govuk-text-input
  autoComplete="address-line2"
  formControlName="line2"
  label="Address line 2 (optional)"
  widthClass="govuk-!-width-two-thirds"
></div>

<div
  govuk-text-input
  autoComplete="address-level2"
  formControlName="city"
  label="Town or city"
  widthClass="govuk-!-width-one-third"
></div>

<div
  govuk-text-input
  autoComplete="county"
  formControlName="county"
  label="County (optional)"
  widthClass="govuk-!-width-one-third"
></div>

<div
  govuk-text-input
  autoComplete="postal-code"
  formControlName="postcode"
  label="Postcode"
  widthClass="govuk-!-width-one-third"
></div>

<div govuk-select ccaCountries formControlName="country" label="Country" labelSize="small"></div>
