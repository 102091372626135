<fieldset govukFieldset>
  <legend govukLegend [hidden]="true">{{ label }}</legend>
  <span govukFieldsetHint>{{ hint }}</span>

  @if (shouldDisplayErrors) {
    <govuk-error-message [errors]="control.errors"></govuk-error-message>
  }

  <div [formGroup]="formGroup" class="govuk-grid-row">
    <div class="govuk-grid-column-one-quarter">
      <div class="govuk-form-group">
        @if (!disabled) {
          <label [for]="id + '.countryCode'" [id]="'l.' + id" class="govuk-label">Country code</label>
          <select
            (blur)="onInputBlur()"
            [class.govuk-select--error]="shouldDisplayErrors"
            [id]="id + '.countryCode'"
            [name]="id + '.countryCode'"
            autocomplete="tel-country-code"
            class="govuk-select"
            formControlName="countryCode"
          >
            @for (option of phoneCodes$ | async; track option) {
              <option [value]="option.value">{{ option.text }}</option>
            }
          </select>
        }
      </div>
    </div>

    <div class="govuk-grid-column-one-half">
      <div class="govuk-form-group">
        <label [for]="id" class="govuk-label">{{ label }}</label>
        @if (!disabled) {
          <input
            (blur)="onInputBlur()"
            [class.govuk-input--error]="shouldDisplayErrors"
            [id]="id"
            [name]="id"
            autocomplete="tel-national"
            class="govuk-input govuk-!-width-full"
            formControlName="number"
            type="tel"
          />
        } @else {
          <p [attr.data-test-id]="id" class="govuk-body">
            {{ valueTransform(control.value) }}
          </p>
        }
      </div>
    </div>
  </div>
</fieldset>
