export enum FileType {
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  VSDX = 'application/vnd.ms-visio.viewer',
  VSD = 'application/vnd.visio',
  PDF = 'application/pdf',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  DIB = 'image/dib',
  BMP = 'image/bmp',
  TIFF = 'image/tiff',
  TXT = 'text/plain',
  CSV = 'text/csv',
}
